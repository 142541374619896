export default {

  props: {

    gui: {
      type: Object,
      required: false
    },

    rootMode: {
      type: Boolean,
      default: false
    },

    node: {
      type: Object,
      required: true
    },

    parentHidden: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      showChildren: false
    }
  },

  computed: {

    showChildCount () {
      return (this.rootMode || this.isBaseGroupType) && !this.isModuleBaseGroup
    },

    isPlaying () {
      if (this.canPlayObject) {
        if (this.gui !== undefined) {
          if (this.gui.animationsActive !== null && this.gui.animationsActive.name === this.node.name && this.gui.animationIsPlaying === true) {
            console.log(this.gui.animationsActive)
            return true
          }
        }
      }
      return false
    },

    nodeChildren () {
      if (this.node.children !== undefined) {
        return this.node.children.filter(row => {
          return row._ignoreSceneGraph === undefined
        })
      }
      return false
    },

    hasChildren () {
      return this.node.children && this.node.children.length > 0
    },

    isActive () {
      return this.activeId !== null && (this.activeId === this.node.id || this.activeId === this.node.name || this.node.uuid === this.activeId)
    },

    hasMaterial () {
      return this.node.material !== undefined
    },

    hasOriginalMaterial () {
      return this.node._originalMaterial !== undefined
    },

    materialCount () {
      if (!this.hasMaterial) return 0
      if (Array.isArray(this.node.material)) return this.node.material.length
      return 1
    },

    materialName () {
      if (!this.hasMaterial) return null
      if (this.materialCount > 1) return this.materialCount + ' Materials'

      let mat = this.node.material
      if (this.node._originalMaterial !== undefined) mat = this.node._originalMaterial

      if (mat.name !== '') return mat.name // + ' ('+mat.id+')'
      return mat.uuid
    },

    isInactiveRootNode () {
      return (this.rootMode === true && this.hasChildren === false)
    },

    childNodeCount () {
      if (this.hasChildren) {
        return this.node.children.length
      }
      return 0
    },

    canDisableObject () {
      return !this.isModuleBaseGroup && !this.isSequenceType && !this.isIgnoredGroup && !this.isScene && !this.isMaterial && !this.isMaterialGroup && !this.isTextureGroup && !this.isTexture && !this.isAnimation && !this.isAnimationGroup
    },

    canFocusObject () {
      // return !this.isMaterial && !this.isMaterialGroup && !this.isTextureGroup && !this.isTexture && !this.isLight && !this.isAnimation && !this.isAnimationGroup
      return false // @todo - we need to fix up the focus logic first
    },

    canCreateObject () {
      return this.isSequenceBaseGroup
    },

    canPlayObject () {
      return this.isAnimation
    },

    isVisible () {
      return this.node.visible
    },

    branchHidden () {
      if (this.isVisible === false) return true
      if (this.parentHidden === true) return true
      return false
    },

    isLine () {
      return (this.node.isLine === true)
    },

    isMesh () {
      return (this.node.isMesh === true)
    },

    isLight () {
      return (this.node.isLight === true)
    },

    isGroup () {
      return (this.node.isGroup === true)
    },

    isScene () {
      return (this.node.isScene === true || this.node.type === 'SceneBaseGroup')
    },

    isIgnoredGroup () {
      return (this.node._ignoreExport === true)
    },

    isGlobalLight () {
      return (this.node._isGlobalLight === true)
    },

    isObject3D () {
      return (this.node.isObject3D === true)
    },

    isTextureGroup () {
      return this.node.type === 'TextureGroup'
    },

    isAnimationGroup () {
      return this.node.type === 'AnimationGroup'
    },

    isAnimation () {
      return this.node.type === 'Animation'
    },

    isTexture () {
      let type = this.node.type
      return type.endsWith('Texture')
    },

    isBaseGroupType () {
      return this.node.type.includes('BaseGroup')
    },

    isPrevizBookmark () {
      return this.node.type === 'PrevizBookmark'
    },

    isSequence () {
      return this.node.type === 'Sequence'
    },

    isSequenceType () {
      return this.node.type.includes('Sequence')
    },

    isSequenceBaseGroup () {
      return this.node.type === 'SequenceBaseGroup'
    },

    isModuleBaseGroup () {
      return this.node.type === 'ModuleBaseGroup'
    },

    isMaterialGroup () {
      return this.node.type === 'MaterialGroup'
    },

    isMaterial () {
      let type = this.node.type
      return type.endsWith('Material')
    },

    isAmbientLight () {
      return (this.node.isAmbientLight === true)
    },

    isHemisphereLight () {
      return (this.node.isHemisphereLight === true)
    },

    hasIcon () {
      return this.nodeIcon !== false
    },

    nodeIcon () {
      if (this.isBaseGroupType) return 'square'
      if (this.isSequence) return 'film'
      if (this.isAnimationGroup) return 'cube'
      if (this.isAnimation) return 'circle'
      if (this.isTextureGroup) return 'cube'
      if (this.isTexture) return 'file-image'
      if (this.isMaterialGroup) return 'cube'
      if (this.isMaterial) return 'game-board-alt'
      if (this.isMesh) return 'cube'
      if (this.isLight) return 'lightbulb'
      if (this.isScene) return 'cube'
      if (this.isGroup) return 'square'
      if (this.isLine) return 'square'
      if (this.isObject3D) return 'square'
      if (this.isPrevizBookmark) return 'camera-movie'
      return false
    },

    nodeIconClass () {
      if (this.isSequenceType) return 'text-purple-500'
      if (this.isBaseGroupType) return 'text-white'
      if (this.isAnimationGroup) return 'text-pink-500'
      if (this.isAnimation) return 'text-pink-500'
      if (this.isTextureGroup) return 'text-green-500'
      if (this.isTexture) return 'text-green-500'
      if (this.isMaterialGroup) return 'text-purple-500'
      if (this.isMaterial) return 'text-purple-500'
      if (this.isLight) return 'text-yellow-500'
      if (this.isMesh) return 'text-blue-500'
      if (this.isScene) return 'text-orange-500'
      if (this.isGroup) return 'text-orange-500'
      if (this.isLine) return 'text-blue-600'
      if (this.isObject3D) return 'text-orange-500'
      if (this.isPrevizBookmark) return 'text-teal-600'

      return ''
    },

    nodeBorderClass () {
      if (this.isSequenceType) return 'border-purple-500'
      if (this.isBaseGroupType) return 'border-white'
      if (this.isAnimationGroup) return 'border-pink-500'
      if (this.isAnimation) return 'border-pink-500'
      if (this.isTextureGroup) return 'border-green-500'
      if (this.isTexture) return 'border-green-500'
      if (this.isMaterialGroup) return 'border-purple-500'
      if (this.isMaterial) return 'border-purple-500'
      if (this.isLight) return 'border-yellow-500'
      if (this.isMesh) return 'border-blue-500'
      if (this.isScene) return 'border-orange-500'
      if (this.isGroup) return 'border-orange-500'
      if (this.isLine) return 'border-blue-600'
      if (this.isObject3D) return 'border-orange-500'
      if (this.isPrevizBookmark) return 'border-teal-600'

      return ''
    }

  },

  methods: {
    toggleChildren () {
      this.showChildren = !this.showChildren
    },

    openChildren () {
      this.showChildren = true
    },

    toggleVisible () {
      this.gui.updateNode(() => {
        this.node.visible = !this.node.visible
      })
    },

    createChildObject () {
      let newNode = this.node.createChild()

      this.openChildren()

      this.viewNode(newNode)
    }
  }
}
