<template>
  <div
    v-if="!isIgnoredGroup"
    class="text-xs my-1 text-gray-300 select-none"
  >
    <div class="flex justify-between pb-1 border-b border-gray-700">
      <div class="left-side truncate mr-2">
        <span
          v-if="hasChildren"
          class="inline-block px-1 cursor-pointer text-gray-400 hover:text-blue-500"
          @click.prevent="toggleChildren"
        >
          <fa-icon
            v-if="showChildren"
            icon="caret-down"
            size="xs"
            class="fa-fw"
          />
          <fa-icon
            v-else
            icon="caret-right"
            size="xs"
            class="fa-fw"
          />
        </span>

        <a
          href="#"
          class="hover:underline cursor-pointer hover:text-white px-1 py-1"
          :class="{ 'text-gray-600': branchHidden, 'bg-gray-700': isActive, 'opacity-50': isInactiveRootNode }"
          @click.prevent="onClick"
          @dblclick.prevent="onFocus"
        >

          <fa-icon
            v-if="hasIcon"
            :icon="nodeIcon"
            :class="nodeIconClass"
            size="sm"
            class="mr-2"
          />
          <fa-icon
            v-else
            icon="circle"
            size="xs"
            class="opacity-25 text-gray-400 mr-2"
          />

          <template v-if="node.name">{{ node.name }}</template>
          <template v-else>{{ node.uuid }}</template>

          <template v-if="rootMode || isBaseGroupType">
            <span
              v-if="isInactiveRootNode"
              class="ml-1"
            >(none)</span>
            <span
              v-else
              class="ml-1"
            >({{ childNodeCount }})</span>
          </template>
        </a>

        <a
          v-if="hasMaterial"
          href="#"
          class="ml-3 text-purple-500 truncate hover:underline cursor-pointer"
          @click.prevent="onClickMaterial"
        >
          <fa-icon
            icon="game-board-alt"
            size="xs"
          /> {{ materialName }}
        </a>
      </div>

      <div class="right-side flex justify-between items-center mr-2">
        <template v-if="canPlayObject">
          <div
            class="cursor-pointer"
            @click.prevent="togglePlayObject"
          >
            <fa-icon
              v-if="isPlaying"
              class="text-gray-400 hover:text-white fa-fw"
              size="sm"
              icon="pause"
            />
            <fa-icon
              v-else
              class="text-gray-400 hover:text-white fa-fw"
              size="sm"
              icon="play"
            />
          </div>
        </template>

        <template v-if="canFocusObject">
          <div
            class="cursor-pointer"
            @click.prevent="focusObject"
          >
            <fa-icon
              class="text-gray-400 hover:text-white fa-fw"
              icon="crosshairs"
            />
          </div>
        </template>

        <template v-if="canDisableObject">
          <div
            class="cursor-pointer"
            @click.prevent="toggleVisible"
          >
            <fa-icon
              v-if="node.visible"
              class="text-gray-400 hover:text-white fa-fw ml-1"
              icon="eye"
            />
            <fa-icon
              v-else
              class="text-gray-600 hover:text-white fa-fw ml-1"
              icon="eye-slash"
            />
          </div>
        </template>

        <template v-if="canCreateObject">
          <div
            class="cursor-pointer"
            @click.prevent="createChildObject"
          >
            <fa-icon
              class="text-gray-400 hover:text-white fa-fw ml-1"
              size="sm"
              icon="plus"
            />
          </div>
        </template>
      </div>
    </div>

    <div
      v-if="hasChildren"
      v-show="showChildren"
      class="ml-4"
    >
      <template v-for="child in nodeChildren">
        <model-scenegraph-node
          :key="child.id"
          :node="child"
          :active-id="activeId"
          :gui="gui"
          :parent-hidden="branchHidden"
          @view="$emit('view', $event)"
          @focus="$emit('focus', $event)"
          @toggle-play="$emit('toggle-play', $event)"
        />
      </template>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line
import mixin from './NodeMixin'
const SceneGraphNode = () => import('@components/editor/scene/model/panels/scenegraph/SceneGraphNode')
const SceneGraph = () => import('@components/editor/scene/model/panels/SceneGraph')

export default {
  name: 'ModelScenegraphNode',

  components: {
    'model-scenegraph-node': SceneGraphNode
  },

  mixins: [mixin],

  props: {
    activeId: {
      default: null
    }
  },

  mounted () {
    // Inspect the immediate child of this node
    // If there's only one, and it's unnamed, collapse the node by default
    if (this.hasChildren) {
      if (this.node.children.length === 1) {
        if (this.node.children[0].name === '') {
          this.showChildren = false
        }
      }
    }
    if (this.rootMode) {
      this.showChildren = true
    }
  },

  methods: {

    togglePlayObject () {
      if (this.gui) {
        this.gui.togglePlayAnimation(this.node.name)
      }
    },

    focusObject () {
      // let focusNode = this.gui.getObjectById(this.node.uuid)
      // this.onView({ id, type, toggle })
      // console.log('Get focus - ', focusNode, this.node.uuid)
      // this.gui.focusObject(focusNode)

      // this.gui.focusObject(focusNode)

      // this.$emit('focus', { id: this.node.uuid, type: 'node' })
    },

    onClick () {
      if (this.hasChildren) this.toggleChildren()

      this.viewNode(this.node)
      // this.$emit('view', { id: this.node.name, type: this.node.type })
    },

    onClickMaterial () {
      if (this.hasOriginalMaterial) {
        this.viewNode(this.node._originalMaterial)
      } else {
        if (this.materialCount === 1) {
          this.viewNode(this.node.material)
          // this.$emit('view', { id: this.node.material.name, type: 'material' })
        }
      }
    },

    viewNode (node) {
      this.$bus.$emit('editor:sidebar', {
        component: SceneGraph,
        props: {
          key: 'node-' + node.uuid,
          node: node,
          gui: this.gui
        }
      })
    },

    onFocus () {
      // if(this.isMaterial) this.$emit('focus',  { id: this.node.name, type: 'materials' })   // Materials aren't accessible by id.
      // else
      // this.$emit('focus', { id: this.node.uuid, type: 'node', toggle: false })

    }
  }
}
</script>
